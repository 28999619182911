import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, useTheme, useMediaQuery, Box } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import HomeIcon from '@mui/icons-material/Home';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { WalletConnectButton } from './ConnectButton';



const LogoImg = styled.img`
    margin: 10px 0 10px;
    width: 60px;
    height: 30px;
    transition: transform 300ms ease, scale 300ms ease, margin 300ms ease;
    @media all and (max-width: 599px){
      margin: 10px 0 6px;
    }
`
const LogoText = styled.img`
  margin: 15px 0 1px 6px;
  width: 97px;
  height: 39px;
  transition: scale 300ms ease;
`
const StyledLogoLink = styled(Link)`
&&{
  &:hover{
    ${LogoImg}{
      transform: rotate(-15deg);
    }
    img{
      scale: 1.08;
    }
  }

}
`
const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  background: #590740;
`
const StyledIconButton = styled(IconButton)`
    
`
const StyledListItem = styled(ListItem)`
&&{
  margin: 0 12px 0 0;
  font-family: "Comic Neue", serif;
  font-weight: 700;
  color: white;
  border-radius: 30px;
  // text-shadow: rgba(0, 0, 0, 0.8) 1px 2px 8px;
  &:hover{
    background: #e381c5;
  }
}
`

const TopBar: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Navigation items can be defined here and reused for both mobile and desktop
  const navItems = [
    { label: 'Home', path: '/', icon: <HomeIcon style={{ margin: '5px 0 -5px '}} /> },
    { label: '404 Launchpad', path: '/mint-sponkeers', icon: <RocketLaunchIcon style={{ margin: '5px 0 -5px' }} /> },
    // Add more navigation items as needed
  ];
  
  {/* { label: 'Shop', path: '/shop', icon: <StoreIcon style={{ margin: '4px 0 -5px' }} /> }, */}
  
  const drawer = (
    <Box
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
      style={{ width: '280px', padding: '60px 20px', height: '100%', background: '#590740'}}
    >
      <List>
        {navItems.map((item, index) => (
          <Link to={item.path} key={index}style={{ textDecoration: 'none' }}>
            <StyledListItem>
             <span>{item.icon} {item.label}</span>
            </StyledListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="sticky">
        <StyledToolbar>
          <StyledLogoLink to="/"><LogoImg src="/images/web-logo.png" width="60px" />
          {!isMobile && (
            <LogoText src="/images/sponk-text.png" width="80px" />
          )}  
          </StyledLogoLink>
          <Box sx={{ display: 'flex', marginRight: '15px' }}>
            {!isMobile && (
              <>
                {navItems.map((item, index) => (
                  <Link to={item.path} key={index} >
                    <StyledListItem sx={{ display: 'inline-block', width: 'auto', margin: '0 2px' }}>
                      <span style={{ textDecoration: 'none' }}>{item.label}</span>
                    </StyledListItem>
                  </Link>
                ))}
              </>
            )}
            <WalletConnectButton />
            {isMobile ? (
            <StyledIconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 0, ml: 2 }}
            >
              <MenuIcon />
            </StyledIconButton>
          ) : null}
          </Box>
        </StyledToolbar>
      </AppBar>
      <Drawer
        anchor={'right'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default TopBar;