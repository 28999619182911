import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';


const ImageText = styled(Typography)`
&&{
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 10px;
  color: black;
  font-weight: 700;
}`

const Image = styled.div`
  position: relative;
  height: 440px;
  width: 100%;
  max-width: 440px; // Adjust based on your design
  margin: 20px auto;
  border-radius: 18px;
  overflow: hidden;

  img, .placeholder {
    width: 100%;
    height: auto;
    transition: opacity 0.3s ease;
    border-radius: 18px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1; // Start the gif as invisible
  }

  .placeholder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7); // Slightly dark background for the warning
    color: white;
    font-size: 16px;
    text-align: center;
    opacity: 1; // Always visible unless hovered
  }

  &:hover img {
    opacity: 1; // Show the gif on hover
  }

  &:hover .placeholder {
    opacity: 0; // Hide the warning text on hover
  }
  &:hover ${ImageText}{
    opacity: 0;
  }
  @media all and (max-width: 499px){
    height: 280px;
    max-width: 280px;
  }
`;



interface TeaserImageProps {
  gif: string;
  placeholder: string;
}

const ImagePreview: React.FC<TeaserImageProps> = ({ gif, placeholder }) => {
  return (
    <Image>
      <img src={gif} alt="GIF preview" />
      <div className="placeholder">
        <img src={placeholder} alt="Placeholder" />
      </div>
      <ImageText>Hover for preview <br /> <span style={{ fontSize: '14px' }}>(epilepsy warning)</span></ImageText>
    </Image>
  );
};

export default ImagePreview;