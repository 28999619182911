import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider, http } from 'wagmi'
import { avalanche } from 'wagmi/chains'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'

import TopBar from './components/TopBar';
import Home from './components/Home';
import Shop from './components/Shop';
import SponkeersNFT from './components/products/SponkeersNFT';
import theme from './theme';
import { ThemeProvider, CssBaseline } from '@mui/material';

/* New API that includes Wagmi's createConfig and replaces getDefaultWallets and connectorsForWallets */
const config = getDefaultConfig({
  appName: 'Sponk World',
  projectId: 'e383e2eaf8bd288bb59d8909bdbf0a04',
  chains: [avalanche],
  transports: {
    [avalanche.id]: http(),
  },
})

const queryClient = new QueryClient()

function App() {
  return (
      <WagmiProvider config={config}>
       <QueryClientProvider client={queryClient}>
          <RainbowKitProvider modalSize="compact">
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router>
                <TopBar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  {/* <Route path="/shop" element={<Shop />} /> */}
                  <Route path="/mint-sponkeers" element={<SponkeersNFT />} />
                </Routes>
              </Router>
            </ThemeProvider>
          </RainbowKitProvider>
       </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;