import React, { useEffect, useState } from 'react';
import { useAccount, useReadContract, useSendTransaction, useWriteContract } from 'wagmi';
import { ethers, parseEther } from 'ethers';

import { Box, Button, Grid, LinearProgress, Typography, Container } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import LanguageIcon from '@mui/icons-material/Language';
import styled from 'styled-components';

import { mintAbi } from './../../abi/mintSponkeersAbi'

import abi from './../../abi/mintSponkeersAbi.json'
import tokenAbi from './../../abi/sponkeersAbi.json'

import { Link } from 'react-router-dom';
import ImagePreview from '../ImagePreview';
import CountdownTimer from '../CountDownTimer';
import { avalanche } from 'wagmi/chains';


const contractAddress = '0x2AdFb8959fbaa0e118bb41FBe4C19D96aa8202E7'; // Update NFTMint contract addresses
const tokenContractAddress = '0x04531Ae0E4Db1A359847Bd6d025044F7eB2CFb3B'; // Update contract addresses
const sampleGif = '/images/nfts/sponkeers/sample-gif.gif';
const samplePlaceholder = '/images/nfts/sponkeers/sample-placeholder.png';

const BackgroundContainer = styled(Box)`
&& {
  position: relative;
  minHeight: 100vh;
  background-color: black;
  overflow: hidden;
  z-index: 0;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 400px;
    background-image: url('/images/nfts/sponkeers/banner.png');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    z-index: 0;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 400px;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 30%, rgba(0,0,0,0.88) 105%);
    pointer-events: none;
    z-index: 0;
  }
  @media all and (max-width: 599px){
    &:before {
      height: 280px;
    }
    &:after {
      height: 280px;
    }
  }
}
`;

const SupplyStat = styled(Box)`
  display: inline-block;
  background: white;
  padding: 0 20px;
  line-height: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 26px;
  color: black;
`
const SocialLink = styled(Link)`
  display: flex;
  margin: 0 0 0 8px;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 26px;
  cursor: pointer;
`

const StyledButton = styled(Button)`
&&{
  display: block;
  text-decoration: none;
  font-family: 'Comic Neue', sans-serif;
  font-size: 20px;
  font-weight: 700;
  padding: 4px 20px;
  border: 3px solid transparent;
  border-radius: 20px;
  color: white;
  background: #000 !important;
  margin: 10px 10px 0;
  &:hover{
    background: #7e085a !important;  
  }
  &:disabled{
    color: #3f3041;
  }
}
`
const BuyButtonWrap = styled(Box)`
&&{
  display: flex;
  justify-content: center;
  @media all and (max-width: 599px){
    flex-direction: column !important;

  }
}
`
const StyledJoeButton = styled(StyledButton)`
&&{
  margin: 5px;
  img{
    height: 80px;
  }
  &:hover{
    opacity: 0.7;
  }
  @media all and (max-width: 599px){
    margin: 5px auto;
  }
}
`
const StyledMintButton = styled(Button)`
&&{
  display: block;
  text-decoration: none;
  font-size: 24px;
  font-weight: 700;
  padding: 4px 20px;
  border: 3px solid #a10d74;
  color: white;
  background: #310423 !important;
  margin: 30px auto 10px;
  min-width: 320px;
  &:hover{
    background: #7e085a !important;  
  }
  &:disabled{
    color: #3f3041;
  }
}
`

const StyledTypography = styled(Typography)`
&&{  
  margin-top: 180px;
  @media all and (max-width: 899px){
    margin-top: 260px;
  }
}
`

const MintPage: React.FC = () => {
  const { isConnected, address } = useAccount();
  const { writeContract, isPending, error } = useWriteContract()

  const [quantity, setQuantity] = useState(1);
  const [mintValue, setMintValue] = useState(2000000000000000000n);
  const [tokensBought, setTokensBought] = useState<number>(0);
  
  const handleQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity);
    if(newQuantity === 1){
      setMintValue(parseEther('2'));
    }else{
      setMintValue(parseEther('4'));
    }
  };

  const { data: saleOpen } = useReadContract({
    address: contractAddress,
    abi: abi,
    functionName: 'saleOpen',
  });

  const { data: publicSaleOpen } = useReadContract({
    address: contractAddress,
    abi: abi,
    functionName: 'publicSaleOpen',
  });

  const { data: isWhitelisted } = useReadContract({
    address: contractAddress,
    abi: abi,
    functionName: 'whitelist',
    args: [address],
  });

  // Used to track the mint progress
  const { data: tokenBalance } = useReadContract({
    address: tokenContractAddress, // Address of the ERC20 token contract
    abi: tokenAbi, // ABI for the ERC20 token contract
    functionName: 'balanceOf',
    args: [contractAddress], // Address of your minting contract
  });

  // Use useContractRead to fetch tokens bought by the user
  // This hook will automatically re-fetch whenever the address or contract state changes
  const { data: tokensBoughtData, refetch: refetchTokensBought } = useReadContract({
    address: contractAddress, // Assuming this is the contract with the tokensBought function
    abi: abi, // Your contract ABI
    functionName: 'tokensBought',
    args: [address],
    // watch: true, This ensures the data is refetched when on-chain state changes
  });

  const totalAvailable = 222; // Total tokens available for mint
  const tokensMinted = 222; // tokenBalance ? totalAvailable - parseInt(tokenBalance.toString()) / Math.pow(10, 18) : 'Loading...'; // Adjust for token decimals if necessary

  const getSaleStatus = () => {
    if (saleOpen && publicSaleOpen) return 'Sale completed'; // 'Public Sale Open';
    if (saleOpen && !publicSaleOpen) return 'WL Sale Open';
    if (!saleOpen && !publicSaleOpen) return 'Opening Soon';
    return 'Sale Closed';
  };

  const getStatusColor = () => {
    if (!saleOpen && !isWhitelisted) return 'red';
    if (!saleOpen && isWhitelisted) return 'orange';
    if (saleOpen && !publicSaleOpen && !isWhitelisted) return 'orange';
    if ((saleOpen && !publicSaleOpen && isWhitelisted) || (saleOpen && publicSaleOpen)) return 'green';
    return 'red'; // Default to red if none of the above conditions are met
  };

  const saleStatus = getSaleStatus();
  const statusColor = getStatusColor();

  const truncateAddress = (address: string) => `${address.slice(0, 6)}...${address.slice(-4)}`;

  /* Check user balance */
  // Effect to update local state when tokensBoughtData changes
  useEffect(() => {
    if (tokensBoughtData) {
      // Assuming tokensBought returns a BigNumber and you want to format it
      const bought = ethers.formatUnits(tokensBoughtData.toString(), 18); // Adjust based on your token's decimals
      setTokensBought(parseFloat(bought));
    }
  }, [tokensBoughtData]);

  // Your existing useEffect for publicSaleOpen and isConnected
  // Adjust this to call refetchTokensBought instead of fetchUserTokens
  useEffect(() => {
    if (publicSaleOpen) {
      const interval = setInterval(() => {
        refetchTokensBought();
      }, 2000); // Refetch every 2 seconds

      return () => clearInterval(interval);
    }
  }, [publicSaleOpen, refetchTokensBought]);

  /* Mint */
  const handleMint = async () => {
    if (!isConnected) {
      console.log('Wallet not connected');
      return;
    }
  
    try {
      // Your existing logic to prepare and send the transaction
      writeContract({
        abi: mintAbi,
        address: '0x2AdFb8959fbaa0e118bb41FBe4C19D96aa8202E7' as `0x${string}`,
        functionName: 'buyTokens',
        value: mintValue
      })
    } catch (error) {
      console.error('Error sending transaction:', error);
    }
  };

  return (
    <BackgroundContainer>
      <Container style={{ position: 'relative', maxWidth: '1800px', minHeight: '100vh', zIndex: '1', marginBottom: '200px' }}>
        <Grid container spacing={2} sx={{  padding: '20px', minHeight: '80vh', alignItems: 'center', justifyContent: 'center', color: 'white'}}>
          {/* Left Column */}
          <Grid item xs={12} md={6} >
            <StyledTypography variant="h6" mb="10px">DN404 MINT</StyledTypography>
            <Typography variant="h1" gutterBottom>Sponkeers 404 (SP404)</Typography>
            <Box style={{ display: 'flex', marginBottom: '50px' }}>
              <Link to="https://snowtrace.io/token/0x04531Ae0E4Db1A359847Bd6d025044F7eB2CFb3B/contract/code?chainId=43114" target="_blank" rel="nooopener"><SupplyStat>Supply: 222 <LaunchIcon style={{ margin: '6px -5px -6px 10px', }} /></SupplyStat></Link>
              <SocialLink to="https://twitter.com/sponkworld" target="_blankk" rel="noopener">
                <TwitterIcon style={{ margin: '5px', width: '30px', height: '30px', fill: 'black'}} />
              </SocialLink>
              <SocialLink to="https://t.me/sponkworld" target="_blankk" rel="noopener">
                <TelegramIcon style={{ margin: '5px', width: '30px', height: '30px', fill: 'black'}} />
              </SocialLink>
              <SocialLink to="/">
                <LanguageIcon style={{ margin: '5px', width: '30px', height: '30px', fill: 'black'}} />
              </SocialLink>
            </Box>

            <Box mb="40px">{/* Time of mint details */}
              <Typography variant="h6" mb="20px" color="#666"><span style={{ color: 'white' }}>MINTING:</span> </Typography>        
              <CountdownTimer endTime={1709474400000} />
              <Typography variant="h6" mt="20px" mb="20px" color="#666"><span style={{ color: 'white' }}>REVEAL:</span> 2HRS POST MINT</Typography>
            </Box>

            <Box mb="40px">
              <Typography variant="h4" mb="10px">TOKEN SUPPLY</Typography>
              <Typography style={{ fontSize: '16px', fontWeight: '700' }}>Total Supply: 222</Typography>
              <Typography style={{ fontSize: '16px' }}>Available to Mint: 155 at 2 AVAX</Typography>
              <Typography style={{ fontSize: '16px' }}>Liquidity Pool: 55</Typography>
              <Typography style={{ fontSize: '16px' }}>Remaining (12): Artist, Competitions and Treasury</Typography>
            </Box>

            <Box mb="40px">
              <Typography variant="h4" mb="10px">ABOUT SPONKEERS</Typography>
              <Typography mb="10px" style={{ fontSize: '16px' }}>
                Sponkeers is one of the first 404s launched on Avalanche. With a low supply of 222 NFTs, it's a Sponk, meme-inspired design by Avellà 
                (<Link to="https://twitter.com/avellArt_" target="_blank" rel="noopener" style={{ color: '#e381c5' }}>@avellArt_</Link>).
              </Typography>
            </Box>

            <Typography variant="h4" mb="20px">MINT SCHEDULE</Typography>
            <Box style={{ padding: '15px', border: '1px solid #444', borderRadius: '12px', marginBottom: '10px' }}>
              <Typography variant="h5" mb="10px">Phase 1: Whitelist (15 minutes)</Typography>
              <Typography style={{ fontSize: '16px' }}>Cost 2 AVAX <span style={{ color: '#444' }}> | </span> Limit 2 per wallet <span style={{ color: '#444' }}> | </span>
               {saleOpen && !publicSaleOpen ? (
                <span style={{ color: '#08e408' }}>Open</span>
               ) : (
                <span style={{ color: '#444' }}>Closed</span>
               )}
              </Typography>
            </Box>
            <Box style={{ padding: '15px', border: '1px solid #444', borderRadius: '12px', marginBottom: '10px' }}>
              <Typography variant="h5">Phase 2: Public</Typography>
              <Typography style={{ fontSize: '16px' }}>Cost 2 AVAX <span style={{ color: '#444' }}> | </span> Limit 2 per wallet <span style={{ color: '#444' }}> | </span>
                {saleOpen && publicSaleOpen ? (
                  <span style={{ color: '#444' }}>Closed {/* this has been set to closed */}</span>
                ) : (
                  <span style={{ color: '#444' }}>Closed</span>
                )}
              </Typography>
            </Box>
            <Typography style={{ fontSize: '16px', margin: '20px 0 5px' }}>Note: During mint, refresh the page regularly to check for updates.</Typography>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Box mb="30px">
              <ImagePreview gif={sampleGif} placeholder={samplePlaceholder} />
            </Box>
            <Typography variant="h3" gutterBottom>Status: {saleStatus}</Typography>
            {saleOpen ? (
              <Box sx={{ width: '100%', maxWidth: '500px', margin: '30px auto 30px'  }}>
                <LinearProgress variant="determinate" value={(Number(tokensMinted) / totalAvailable) * 100} style={{ margin: '0 auto', maxWidth: '440px', boxShadow: '0 0 10px #a10d74', height: '12px', border: '2px solid #e381c5' }} />
                <Typography variant="body1" sx={{ margin: '10px auto', textAlign: 'center',  }}>{`${tokensMinted} / ${totalAvailable} minted`}</Typography>
              </Box>
             ): ('')}
            {isConnected ? (
              <>
                <Box display="flex" alignItems="center" mt="20px" mb="30px" justifyContent="center">
                  <div className={`pulsing-dot red`}></div> {/* note to activate use ${statusColor} as a class on pulsing-dot and change to Public sale "open" below */}
                  <Typography variant="body1" ml={1} > 
                   <span style={{ fontWeight: '700' }}>{publicSaleOpen && saleOpen ? "Public sale closed" : isWhitelisted ? "You ARE whitelisted" : "You are NOT whitelisted"}</span> ({truncateAddress(address ?? '')})                  </Typography>
                </Box>
                {/*
                {saleOpen && (
                  <>
                    <Typography variant="h4" style={{ margin: '20px 0 5px' }}>Mint:</Typography>
                    <Box display="flex" justifyContent="center" >
                      <StyledButton
                        variant="contained"
                        onClick={() => handleQuantityChange(1)}
                        disabled={tokensBought >= 2}
                        style={{ 
                          border: quantity === 1 ? '3px solid #a10d74' : '3px solid transparent'
                        }}
                      >
                        1 Token
                      </StyledButton>
                      <StyledButton
                        variant="contained"
                        onClick={() => handleQuantityChange(2)}
                        disabled={tokensBought >= 1}
                        style={{ 
                          border: quantity === 2 ? '3px solid #a10d74' : '3px solid transparent'
                        }}
                      >
                        2 Tokens
                      </StyledButton>
                    </Box>
                  </>
                )}
                */}
              </>
            ) : (
              <Typography variant="body1" color="#444" mt="20px">Connect your wallet {/* to check WL status */}</Typography>
            )}
            {saleOpen ? (
              <>
                {/*
                {isConnected && (
                  <>
                    <StyledMintButton variant="contained" onClick={() => handleMint() } disabled={isPending || !isConnected || tokensBought >= 2} sx={{ marginTop: '10px', marginBottom: '10px' }}>
                      {isPending ? 'Processing...' : `Mint ${quantity} Token${quantity > 1 ? 's' : ''} for ${2 * quantity} AVAX`}
                    </StyledMintButton>
                    {isConnected && (
                      <Typography style={{ color: tokensBought >= 1 ? 'white' : 'rgb(68, 68, 68)', marginTop: '20px' }}>
                        {tokensBought >= 2 && 'Complete: '} You have minted {tokensBought}
                      </Typography>
                    )}
                  </>
                )}
                */}
              </>
            ) : (
              <Typography variant="h3" mt="30px" mb="10px" color="#444">Mint is NOT Open</Typography>
            )}
            {error && <Typography color="error" mt="15px">{error.message.split("Raw Call Arguments:")[0]}</Typography>}
          </Grid>
        </Grid>
          <Box style={{ margin: '100px 0 80px', textAlign: 'center' }}>
              <Typography style={{ color: 'white'}} mb="20px">Trade Sponkeers 404 on</Typography>
              <BuyButtonWrap>
                <Link to="https://traderjoexyz.com/avalanche/trade?outputCurrency=0x04531Ae0E4Db1A359847Bd6d025044F7eB2CFb3B" target="_blank" rel="noopener">
                  <StyledJoeButton style={{ border: '3px solid #a10d74', padding: 0, height: '84px', overflow: 'hidden' }}>
                    <img src="/images/tj-button-1.png"alt="" />
                  </StyledJoeButton>
                </Link>
                <Link to="https://joepegs.com/collections/avalanche/0x027Cc1f785a3857701eE91a796A9525DB4f75e34" target="_blank" rel="noopener">
                  <StyledJoeButton style={{ border: '3px solid #a10d74', padding: 0, height: '84px', overflow: 'hidden' }}>
                    <img src="/images/tj-button-2.png" alt="" />
                  </StyledJoeButton>
                </Link>
              </BuyButtonWrap>
          </Box>
      </Container>
    </BackgroundContainer>
  );
};

export default MintPage;
