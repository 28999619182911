import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Box, Typography, Grid, Container, Button } from '@mui/material';
import styled, { keyframes, css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
// import CountDownTimer from './CountDownTimer';
import TokenDistributionChart from './TokenDistributionChart';

import sponkAbi from '../abi/sponkAbi.json';
import wavaxAbi from '../abi/wavaxAbi.json';
import usdcAbi from '../abi/usdcAbi.json';
import lpAbi from '../abi/lpAbi.json';

// Social media icons
import TwitterIcon from './icons/Avax';
import Avax from './icons/Avax';
import { Link } from 'react-router-dom';
import Twitter from './icons/Twitter';
import { CopyAll, Telegram } from '@mui/icons-material';
import TweetEmbed from './TweetEmbed';
import ImageWithLightbox from './ImageWithLightbox';
import ImageWithLightbox2 from './ImageWithLightbox2';
import { CovalentClient } from '@covalenthq/client-sdk';

// Styled components
const FullScreenContainer = styled.div`
  min-height: 100vh;
  padding: 60px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HomeSection = styled(FullScreenContainer)`
  background-color: black;
  background-image: url('/images/sponk-background.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  // background-attachment: fixed;
  background-size: cover;
`;
const ButtonBox = styled(Box)`
  display: flex;
  justify-content: center;
  @media all and (max-width: 599px){
    flex-direction: column;
  }
`
const SecondSection = styled(FullScreenContainer)`
  position: relative;
  flex-direction: column;  
  background: linear-gradient(#390429, #A10D74);
`;
const StatsSection = styled(FullScreenContainer)`
  position: relative;
  flex-direction: column;  
  background-color: #01022e;
`
const Sponk = styled.img`
  display: block;
  width: 422px;
  max-width: 70%;
  height: auto;
  margin: 0 auto;
  @media all and (max-width: 449px){
    margin-top: 40px;
  }
`;
const SponkText = styled.img`
  display: block;
  width: 600px;
  max-width: 90%;
  height: auto;
  margin: 0 auto 10px;
`;

const StyledButton = styled(Button)`
&&{
  display: block;
  text-decoration: none;
  font-size: 24px;
  font-weight: 700;
  padding: 4px 20px;
  border: 3px solid #a10d74;
  color: white;
  background: #310423 !important;
  margin: 10px auto 10px;
  transition: scale 300ms, transform 300ms;
  &:hover{
    background: #310423 !important;
    scale: 1.1;
    transform: rotate(-8deg);
  }
}
`
const StyledButtonSecondary = styled(StyledButton)`
&&{
  border: 3px solid #a10d74;
  color: white;
  background: #310423 !important;
  &:hover{
    background: #310423 !important;
  }
}
`
const StatsContainer = styled(Box)`
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media all and (max-width: 449px){
    flex-direction: column;
  }
`
const StatsBox = styled(Box)`
  background: #FAFAFA;
  width: 33%;
  max-width: 180px;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  padding: 4px;
  text-align: center;
  @media all and (max-width: 449px){
    width: 80%;
    margin: 0 auto 10px;
  }
  &:hover{
    background: #e2e2e2;
  }
`;
const StatText = styled(Box)`
  font-size: 28px;
  font-weight: 600;
  color: #555;
`;
const StatHeading = styled(Box)`
  color: #555;
`;


const InformationSection = styled(FullScreenContainer)`
  background: #f0f0f0;
  flex-direction: column;
`;

const TwoColContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 1200px;
  max-width: 90%;
  > div:first-child{
    width: 40%
  }
  > div:nth-child(2){
    width: 58%
  }
  @media all and (max-width: 949px){
    flex-direction: column;
      > div:first-child{
      width: 100%
    }
    > div:nth-child(2){
      width: 100%
    }
  }
`
const SponkLooking = styled.img`
  display: block;
  position: absolute;
  bottom: -72px;
  width: 100%;
  max-width: 2000px;
  z-index: 1;
  @media all and (min-width: 2000px){
    bottom: -60px;
  }
`
const SocialMediaSection = styled(FullScreenContainer)`
  display: flex;
  flex-direction: column;
  background-color: #222;
  background-image: url('images/background-3.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 80%;
`;


const WhiteSection = styled(FullScreenContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: Calc(100vh + 100px);
  @media all and (max-height: 600px){
    min-height: Calc(100vh + 240px) !important;
  }
`
const WhiteSectionText = styled(Box)`
  display: block;
  margin: 0 auto;
  max-width: 1080px;
  margin-top: -300px;

  @media all and (max-width: 600px){
    margin-top: -40px;
  }
`
const SocialIconContainer = styled.div`
  color: white;
  & > svg {
    margin: 0 10px;
    font-size: 40px;
  }
`;
const StyledLink = styled(Link)`
&& {
  &:hover{
    > svg{
      scale: 1.2;
    }
  }
}
`

// Animated components
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const zoomIn = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const animationStyles = css`
  animation: ${fadeIn} 1s ease, ${zoomIn} 1s ease;
  animation-fill-mode: forwards;
`;

const Animation = styled.div<{ inView: boolean }>`
  margin: 0 auto;
  opacity: 0;
  transform: scale(0);
  ${({ inView }) => inView && animationStyles}
`;

const animationWithDelayStyles = css`
  animation: ${fadeIn} 1s ease 500ms, ${zoomIn} 1s ease 500ms;
  animation-fill-mode: forwards;
`;

const AnimationDelayed = styled.div<{ inView: boolean }>`
  display: block;
  margin: 0 auto;
  opacity: 0;
  transform: scale(0);
  ${({ inView }) => inView && animationWithDelayStyles}
`;

const MemeWebsite: React.FC = () => {
  
  const tokenAddress = '0xa82c685fBB60c6912cfD279C3A0BeA1b0d5850e1' // The SPONK token

  /* 
  
  const walletAddress = '0x6Ab6248162f903e5561bd57D832d0701e37aD01b' // The reserve wallet REPLACE
  
  const wavaxContractAddress = '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7' // The Wavax token
  const usdcContractAddress = '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E' // The Usdc token

  const sponkAvaxLpAddress = '0x9fa0DE3053f8358325f7ffE8474F52b4e306b09E' // The SPONK LP REPLACE
  const avaxUsdcLpAddress = '0xf4003F4efBE8691B60249E6afbD307aBE7758adb'; // The Avax USDC LP
  
  const [sponkBalance, setsponkBalance] = useState<number>(0);
  const [usdValue, setUsdValue] = useState<number>(0);

  const [totalSupply, setTotalSupply] = useState<any>(0);
  const [burnedSupply, setBurnedSupply] = useState<number>(0);


  
  const provider = new ethers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc");

  const fetchFagmiExchangeRate = async (
    lpContractAddress: string, 
    tokenAContractAddress: string, 
    tokenBContractAddress: string
  ) => {
    try {
      const lpContract = new ethers.Contract(lpContractAddress, lpAbi, provider);
      const tokenAContract = new ethers.Contract(tokenAContractAddress, sponkAbi, provider);
      const tokenBContract = new ethers.Contract(tokenBContractAddress, wavaxAbi, provider);
      
      const reserves = await lpContract.getReserves();
      const tokenADecimals = await tokenAContract.decimals();
      const tokenBDecimals = await tokenBContract.decimals();
  
      // Convert BigInt values to strings and then to numbers
      const reserveA = parseFloat(ethers.formatUnits(reserves[0], tokenADecimals));
      const reserveB = parseFloat(ethers.formatUnits(reserves[1], tokenBDecimals));
  
      const exchangeRate = reserveB / reserveA;
      return exchangeRate;
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      throw error;
    }
  };

  const fetchAvaxExchangeRate = async (
    lpContractAddress: string, 
    tokenAContractAddress: string, 
    tokenBContractAddress: string
  ) => {
    try {
      const lpContract = new ethers.Contract(lpContractAddress, lpAbi, provider);
      const tokenAContract = new ethers.Contract(tokenAContractAddress, sponkAbi, provider);
      const tokenBContract = new ethers.Contract(tokenBContractAddress, wavaxAbi, provider);
      
      const reserves = await lpContract.getReserves();
      const tokenADecimals = await tokenAContract.decimals();
      const tokenBDecimals = await tokenBContract.decimals();
  
      // Convert BigInt values to strings and then to numbers
      const reserveA = parseFloat(ethers.formatUnits(reserves[0], tokenADecimals));
      const reserveB = parseFloat(ethers.formatUnits(reserves[1], tokenBDecimals));
  
      const exchangeRate = reserveB / reserveA ;
      return exchangeRate;
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchBalanceAndValue = async () => {
      const sponkContract = new ethers.Contract(tokenAddress, sponkAbi, provider);

      const balance = await sponkContract.balanceOf(walletAddress);
      const balanceNumber = parseFloat(ethers.formatEther(balance));
      setsponkBalance(balanceNumber);

      const sponkToAvaxRate = await fetchFagmiExchangeRate(sponkAvaxLpAddress, tokenAddress, wavaxContractAddress);
      const avaxToUsdcRate = await fetchAvaxExchangeRate(avaxUsdcLpAddress, wavaxContractAddress, usdcContractAddress);

      const calculatedUsdValue = balanceNumber * (sponkToAvaxRate * avaxToUsdcRate);
      setUsdValue(calculatedUsdValue);

      const total = await sponkContract.totalSupply();
      const totalNumber = parseFloat(ethers.formatEther(total));
      setTotalSupply(totalNumber);

    };

    if (walletAddress && tokenAddress && sponkAvaxLpAddress && avaxUsdcLpAddress) {
      fetchBalanceAndValue();
    }
  }, [walletAddress, tokenAddress, sponkAvaxLpAddress, avaxUsdcLpAddress]);

  */

  
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(tokenAddress);
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Reset notification after 2 seconds
    } catch (err) {
      setCopySuccess('Failed to copy');
    }
  };

  interface ScrollingTextProps {
    text: string;
  }
  
  const ScrollingText: React.FC<ScrollingTextProps> = ({ text }) => {
    const repeatedText = Array(1200).fill(text).join(" ");
  
    return (
      <div className="scrolling-text-container">
        <div className="scrolling-text">
          {repeatedText.split(" ").map((word, index) => (
            <span key={index}>{word}</span>
          ))}
        </div>
      </div>
    );
  };


  const [sponkRef, sponkInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [sponkTextRef, sponkTextInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [text1Ref, text1InView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [text2Ref, text2InView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [ctaButtonRef, ctaButtonInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [image3Ref, image3InView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [text3Ref, text3InView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [footer1Ref, footer1InView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [footer2Ref, footer2InView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });


  const repeatedText = `SPONK`

  return (
    <Box>
      <HomeSection>
        <Box>
          <div ref={sponkRef}>
            <Animation inView={sponkInView}>
              <Sponk src="/images/sponk.png" alt="" className="hover-up-down" />
            </Animation>
          </div>
          <div ref={sponkTextRef}>
            <AnimationDelayed inView={sponkTextInView}>
              <SponkText src="/images/sponk-text.png" alt="" className="shake-on-hover"/>
            </AnimationDelayed>
          </div>

          <Typography variant="h3" textAlign="center" color="white" fontSize="24px" lineHeight="30px" style={{ 
            fontFamily: 'Comic Neue, sans-serif', 
            fontWeight: '700', 
            textShadow: '1px 2px 8px rgba(0,0,0,0.8)',
            maxWidth: '600px'
            }}
            >Sponk follows the adventures of a lovable galago primate, who is learning all about the world.</Typography>
            <ButtonBox>
                <Link to="https://zswap.plus/swap?outputCurrency=0xa82c685fBB60c6912cfD279C3A0BeA1b0d5850e1" rel="noopener" target="_blank" style={{ textDecoration: 'none', margin: '0 10px 0 0' }} >
                  <StyledButton>BUY <span style={{ color: '#e381c5' }}>SPONK</span></StyledButton>
                </Link>
                <Link to="/mint-sponkeers" style={{ textDecoration: 'none', margin: '0 5px 0 10px' }} >
                  <StyledButtonSecondary><span style={{ color: '#a10d74' }}>SPONKEERS</span> 404</StyledButtonSecondary>
                </Link>
            </ButtonBox>
          <Typography variant="h3" textAlign="center" color="white" fontSize="20px" lineHeight="28px" mt="20px" style={{ 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Comic Neue, sans-serif', 
            fontWeight: '700', 
            textShadow: '1px 2px 8px rgba(0,0,0,0.8)',
            maxWidth: '600px'
            }}
            ><img src="/images/abc-logo.png" alt="ABC" style={{ width: '80px', minWidth: '80px', marginRight: '10px' }} className="shake-on-hover" /><span>&copy; 1996 Avalanche Broadcasting Corporation.</span></Typography>
        </Box>
      </HomeSection>
      <SecondSection>
        <TwoColContainer>
          <Box>
            <ImageWithLightbox2 />
            <Typography fontSize="22px" color="#e381c5" fontWeight="700" fontFamily="'Comic Neue', sans-serif" >Collector's Edition DVD: The BEST of Sponk</Typography>
            <Typography fontSize="18px" color="#FFF" fontWeight="700" fontFamily="'Comic Neue', sans-serif" mb="30px" style={{ maxWidth: '360px' }} >Hilarious bloopers include: Sponk still sleeping, Sponk still drunk, Sponk falls off stage 2, and much more.</Typography>
          </Box>
          <Box>
            <Typography fontFamily="'Balthazar', serif" textAlign="right" fontSize="16px" color="#ccc"><span style={{ color: '#e381c5' }}>SPONK</span> TOKENOMICS</Typography>
            <div ref={text1Ref}>
              <AnimationDelayed inView={text1InView}>
                <Box>
                  <Typography fontFamily="'Balthazar', serif" fontSize="42px" lineHeight="52px" mt="4px" mb="12px" color="#e381c5" fontWeight="700">Get $SPONK Now on Avalanche</Typography>
                  <Typography fontFamily="'Balthazar', serif" fontSize="30px" lineHeight="38px" mb="14px" color="#e381c5">Total Supply:  <span style={{ color: 'white' }}>50T</span> <span style={{ fontSize: '18px'}}>SPONK</span></Typography>
                  <Typography fontFamily="'Balthazar', serif" fontSize="30px" lineHeight="38px" mt="15px" mb="10px" color="white" fontWeight="700">Supply breakdown:</Typography>
                  <Typography fontFamily="'Balthazar', serif" mb="4px" color="#fff"><span style={{ color: '#e381c5' }}>50%</span> initial liquidity (LP burned: 50 AVAX)</Typography>
                  <Typography fontFamily="'Balthazar', serif" mb="4px" color="#fff"><span style={{ color: '#e381c5' }}>50%</span> airdropped to the AVAX community via a range of meme projects</Typography>
                  <Typography fontFamily="'Balthazar', serif" mb="20px" color="#fff"><span style={{ color: '#e381c5' }}>No influencer or marketing allocations.</span> All real community growth. </Typography>
                  <Typography mb="30px" color="#fff"><span style={{ color: '#e381c5' }}>SPONK</span> was launched as a recovery project in January 2024, with tokens fairlaunched via liquidity and airdropped to long term holders of trusted memes and projects on AVAX.</Typography>
                  <Typography fontFamily="'Balthazar', serif" mb="4px" color="#fff">Get SPONK on <a href="https://zswap.plus/swap?outputCurrency=0xa82c685fBB60c6912cfD279C3A0BeA1b0d5850e1" rel="noopener" target="_blank" style={{ color: '#e381c5' }}>ZSwap</a> now:</Typography>
                  <Typography color="#fff" fontSize="18px" style={{ fontFamily: "'Balthazar', serif", margin: '5px 0', wordBreak: 'break-word' }}>
                    {tokenAddress}<Button onClick={copyToClipboard} style={{ margin: '-3px 0 3px 5px', padding: '5px 10px', minWidth: 0 }} ><CopyAll style={{ color: '#e381c5' }} /></Button> {copySuccess && <span style={{ color: '#e381c5', position: 'absolute' }}>{copySuccess}</span>}
                  </Typography>
                  <Typography fontFamily="'Balthazar', serif" fontSize="24px" lineHeight="32px" fontWeight="600" mt="30px" color="#fff">Contract renounced, LP burned, supply fully emitted.</Typography>
                </Box>
              </AnimationDelayed>
            </div>
          </Box>
        </TwoColContainer>
      </SecondSection>
      <ScrollingText text={repeatedText} />
      <InformationSection>
        <TwoColContainer>
          <Box>
            <ImageWithLightbox />
            <Typography fontSize="20px" fontWeight="700" color="#555" mb="30px" >Sponk is now touring. See Sponk live on <a href="https://zswap.plus/swap?outputCurrency=0xa82c685fBB60c6912cfD279C3A0BeA1b0d5850e1" rel="noopener" target="_blank" style={{ color: '#e381c5' }}>ZSwap DEX</a>.</Typography>
            <div ref={ctaButtonRef}>
            <AnimationDelayed inView={ctaButtonInView}>
              <Link to="https://zswap.plus/swap?outputCurrency=0xa82c685fBB60c6912cfD279C3A0BeA1b0d5850e1" rel="noopener" target="_blank" style={{ textDecoration: 'none' }} >
                <StyledButton>See <span style={{ color: '#a10d74' }}>SPONK</span> ON STAGE</StyledButton></Link>
            </AnimationDelayed>
          </div>
          </Box>
          <Box>
            <Typography fontFamily="'Balthazar', serif" textAlign="right" fontSize="16px" color="#999"><span style={{ color: '#e381c5' }}>SPONK</span> INTEGRATIONS</Typography>
            <div ref={text2Ref}>
              <AnimationDelayed inView={text2InView}>
                <Box>
                  <Typography fontFamily="'Balthazar', serif" fontSize="42px" lineHeight="52px" mt="4px" mb="12px" fontWeight="700" color="#444">Enjoy Sponk</Typography>
                  <Typography fontSize="24px" lineHeight="32px" mt="15px" mb="10px" fontWeight="700" color="#444">Stage 1: Spread <span style={{ color: '#e381c5' }}>SPONK</span> everywhere!</Typography>
                  <Typography fontSize="24px" lineHeight="32px" mt="15px" mb="10px" fontWeight="700" color="#444">Sponk will tour many <span style={{ color: '#e381c5' }}>DEXs</span>, <span style={{ color: '#e381c5' }}>CEXs</span> and <span style={{ color: '#e381c5' }}>DeFi</span> applications.</Typography>
                  <Typography fontSize="30px" lineHeight="38px" mt="15px" mb="10px" fontWeight="700" color="#444">Current stats:</Typography>
                  <StatsContainer>
                    <StatsBox className="shake-on-hover">
                      <StatText>1,700+</StatText>
                      <StatHeading>Holders</StatHeading>
                    </StatsBox>
                    <StatsBox className="shake-on-hover">
                      <StatText>2</StatText>
                      <StatHeading>Integrations</StatHeading>
                    </StatsBox>
                    <StatsBox className="shake-on-hover">
                      <StatText>1</StatText>
                      <StatHeading>Chain</StatHeading>
                    </StatsBox>
                  </StatsContainer>
                  <Typography mt="45px" mb="10px" color="#444" fontFamily="Comic Neue, sans-serif" fontSize="36px" lineHeight="44px" fontWeight="700">The FUN never ends with <span style={{ color: '#e381c5' }}>SPONK</span>!*</Typography>
                  <Typography mt="20px" mb="10px" color="#444" fontFamily="Comic Neue, sans-serif" fontSize="20px" lineHeight="28px" fontWeight="700">After 25 years, Sponk is still a children's favourite. See Sponk live in person!</Typography>
                  <Typography fontSize="17px" lineHeight="20px" mb="10px" color="#444">* Fun may be relative to you and your personal situation. There is no guarantee when the fun will start or end, or whether it will be fun at all. Sponk may leave any show at any time. Refunds will not be provided.</Typography>
                </Box>
              </AnimationDelayed>
            </div>
          </Box>
        </TwoColContainer>
      </InformationSection>
      <WhiteSection>
          <WhiteSectionText style={{ zIndex: '2', textAlign: 'center', }}>
            <Box>
              <div ref={image3Ref}>
                <Animation inView={image3InView}>
                  <img src="/images/sponk-eyes-icon.png" alt="" width="120px" className="shake-on-hover" />
                </Animation>
              </div>
            </Box>
            <Box>
              <Typography fontFamily="'Balthazar', serif" textAlign="right" fontSize="16px" color="#999">ABOUT <span style={{ color: '#e381c5' }}>SPONK</span></Typography>
              <div ref={text3Ref}>
                <AnimationDelayed inView={text3InView}>
                  <Typography fontFamily="'Balthazar', serif" fontSize="40px" lineHeight="38px" mt="20px" mb="10px" color="#e381c5" fontWeight="700" style={{ fontWeight: '600', color: '#444', backgroundColor: 'white' }}>Who is Sponk?</Typography>
                  <Typography fontSize="28px" lineHeight="36px" color="#e381c5" mt="20px" style={{ fontFamily: 'Comic Neue, sans-serif', fontWeight: '700', color: '#e381c5', backgroundColor: 'white'}}>
                    Sponk, now 37, is an ex-child star, famous for playing himself in the two-time award-winning, hit 1990s self-titled TV show, Sponk!</Typography>
                  <Typography fontSize="20px" lineHeight="26px" color="#888" mt="30px" style={{ fontFamily: 'Comic Neue, sans-serif', fontWeight: '700', backgroundColor: 'white' }}>
                    <span style={{ color: '#444', fontWeight: '700' }} >Parental Note:</span> Sponk is moving beyond a phase of degenerate gambling and strife, pursuing a path of reform. We ask that you respect his commitment to sobriety at this time. Live appearances may not be suitable for all audiences.
                  </Typography>
                </AnimationDelayed>
             </div>
            </Box>
          </WhiteSectionText>
          
        <SponkLooking src="/images/sponk-looking.gif" alt="" />
      </WhiteSection>
      
      <SocialMediaSection>
        <SocialIconContainer>
          <div ref={footer1Ref}>
            <Animation inView={footer1InView}>
              <SponkText src="/images/sponk-text.png" alt="" style={{ marginTop: '12px', width: '340px', maxWidth: '80%' }} className="shake-on-hover" />
            </Animation>
          </div>
          <Box style={{ margin: '0 auto', textAlign: 'center' }}>
            <StyledLink to="https://twitter.com/sponkworld" rel="noopener" target="_blank">
              <Twitter width="48px" color="white"  />
            </StyledLink>
            <StyledLink to="https://t.me/sponkworld" rel="noopener" target="_blank">
              <Telegram style={{ fontSize: '60px', color: 'white', margin: '4px 0 -4px 32px' }} />
            </StyledLink>
          </Box>
          <div ref={footer2Ref}>
            <AnimationDelayed inView={footer2InView}>
              <Link to="https://zswap.plus/swap?outputCurrency=0xa82c685fBB60c6912cfD279C3A0BeA1b0d5850e1" rel="noopener" target="_blank" style={{ textDecoration: 'none' }} >
                <StyledButton>Buy <span style={{ color: '#a10d74' }}>SPONK</span></StyledButton>
              </Link>
            </AnimationDelayed>
          </div>
        </SocialIconContainer>
        <Box style={{ display: 'block', width: '800px', maxWidth: '92%',  margin: '140px auto 40px', border: '3px solid #555', borderRadius: '18px', padding: '20px 20px', color: 'white', textAlign: 'center', fontFamily: 'sans-serif', fontSize: '18px' }}>
          <span style={{ color: '#a10d74',  }}>SPONK</span> is a meme coin with no intrinsic value or expectation of financial return. <br />There is no formal team or roadmap. The coin is for entertainment purposes only.
         </Box>
         <Typography fontSize="14px" color="white">© Copyright 2021: Sponk World</Typography>
      </SocialMediaSection>
    </Box>
  );
};

export default MemeWebsite;