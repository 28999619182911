import { Box } from '@mui/material';
import React, { useState } from 'react';
import ModalImage from 'react-modal-image';
import styled, { keyframes, css } from 'styled-components';
import { useInView } from 'react-intersection-observer';

// Animated components
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const zoomIn = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const animationStyles = css`
  animation: ${fadeIn} 1s ease, ${zoomIn} 1s ease;
  animation-fill-mode: forwards;
`;

const Animation = styled.div<{ inView: boolean }>`
  margin: 0 auto;
  opacity: 0;
  transform: scale(0);
  ${({ inView }) => inView && animationStyles}
`;


const ImageWithLightbox: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const imageStyle = {
    width: '280px',
    maxWidth: '80%',
    borderRadius: '12px',
    marginTop: '0',
    marginBottom: '10px',
    cursor: 'pointer'
  };

  const lightboxWrapperStyle = {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: 1000,

  };

  const [image2Ref, image2InView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div>
      <div ref={image2Ref}>
        <Animation inView={image2InView}>
          <Box>
            <img
              src="/images/sponk-dvd-2.png"
              alt="Sponk World Tour"
              style={imageStyle}
              onClick={() => setIsOpen(true)}
            />
          </Box>
        </Animation>
      </div>

      {isOpen && (
        <Box position="fixed" style={lightboxWrapperStyle} onClick={() => setIsOpen(false)}>
          <ModalImage
            small="/images/sponk-dvd-2.png"
            large="/images/sponk-dvd-2.png"
            alt="Sponk DVD"
            hideDownload={true}
            hideZoom={true}
            imageBackgroundColor="transparent"
            className="custom-modal-image-style"
          />
        </Box>
      )}
    </div>
  );
};

export default ImageWithLightbox;