import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  endTime: number; // endTime as epoch time in milliseconds
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ endTime }) => {
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [milliseconds, setMilliseconds] = useState<number>(0);
  const [timerActive, setTimerActive] = useState<boolean>(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const distance = endTime - currentTime;
      
      if (distance < 0) {
        clearInterval(interval);
        setTimerActive(false);
      }

      setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
      setMilliseconds(Math.floor((distance % 1000)));

    }, 100);

    return () => clearInterval(interval);
  }, [endTime]);

  const formatTimeUnit = (unit: number) => unit.toString().padStart(2, '0');

  if(!timerActive){
    return(
      <Typography fontSize="40px" mb="10px">Now</Typography>
    )
  }

  return (
    <Typography className="countdown" style={{ textShadow: '2px 2px 10px rgba(0,0,0,0.5)', }}>
        {days > 0 && (<span style={{ width: '70px', display: 'inline-block', whiteSpace: 'nowrap', textAlign: 'center' }}><span style={{ fontSize: '40px'}}>{formatTimeUnit(days)}</span>D</span>)}
        <span style={{ width: '70px', display: 'inline-block', whiteSpace: 'nowrap', textAlign: 'center' }}><span style={{ fontSize: '40px'}}>{formatTimeUnit(hours)}</span>H</span>
        <span style={{ width: '70px', display: 'inline-block', whiteSpace: 'nowrap', textAlign: 'center' }}><span style={{ fontSize: '40px'}}>{formatTimeUnit(minutes)}</span>M</span>
        <span style={{ width: '65px', display: 'inline-block', whiteSpace: 'nowrap', textAlign: 'center' }}><span style={{ fontSize: '40px'}}>{formatTimeUnit(seconds)}</span>S</span>
        {/* <span style={{ width: '50px', display: 'inline-block', whiteSpace: 'nowrap', textAlign: 'center' }}><span style={{ fontSize: '26px'}}>{milliseconds}</span>ms</span> */}
    </Typography>
  );
};

export default CountdownTimer;