import React from 'react';
import { Box, Button } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { styled } from '@mui/system';
import '@rainbow-me/rainbowkit/styles.css';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

const StyledConnectButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.getContrastText(theme.palette.background.paper),
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
  fontSize: '19px',
  fontWeight: 700,
  borderRadius: 24,
  textTransform: 'none',
  padding: '6px 16px',
  marginLeft: '12px',
  minWidth: '120px',
  whiteSpace: 'nowrap'
}));

export const WalletConnectButton = () => (
  <ConnectButton.Custom>
    {({ account, openConnectModal, openAccountModal }) => {
      if (!account) {
        return (
          <StyledConnectButton onClick={openConnectModal}>
            <AccountBalanceWalletIcon style={{ marginRight: '12px' }} /> Connect
          </StyledConnectButton>
        );
      }

      return (
        <StyledConnectButton
          /*
          onMouseOver={(e) => (e.currentTarget.textContent = "Disconnect")}
          onMouseOut={(e) => (e.currentTarget.textContent = account.displayName)}
          */
          onClick={openAccountModal}
        >
          <AccountBalanceWalletOutlinedIcon style={{ marginRight: '12px' }} /> {account.displayName}
        </StyledConnectButton>
      );
    }}
  </ConnectButton.Custom>
);